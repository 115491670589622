import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import close from '../images/close-stats.png';

export default function Home() {

    const [userData, setUserData] = useState({ profile: 0 });
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const fetchUserData = () => {

        const userLogged = localStorage.getItem('userLogged');
        const userInfo = JSON.parse(userLogged);
        console.log(userInfo);
        const xhr = new XMLHttpRequest();
        let url = `${process.env.REACT_APP_API_URL}/user/${userInfo.id}`;
        //let url = `http://wineapi/api/user/${userInfo.id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const userData = JSON.parse(this.responseText);
                    setUserData(userData);
                    console.log("Datos del usuario:", userData);
                    setFormData({
                        username: userData.username,
                        email: userData.email,
                        password: '',
                        confirmPassword: ''
                    });
                } else {
                    console.error("Error al obtener los datos del usuario:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    useEffect(() => {
        fetchUserData();
    }, []);


    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleConfirm = async (e) => {
        e.preventDefault();

        // Clear previous errors
        setErrors({});

        // Check username length
        if (formData.username.length < 2 || formData.username.length > 15) {
            setErrors(errors => ({ ...errors, username: "El usuario debe tener entre 2 y 15 caracteres" }));
            return;
        }

        // Check email format
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors(errors => ({ ...errors, email: "Formato de correo electrónico inválido" }));
            return;
        }

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('username', formData.username);
            data.append('email', formData.email);

            //xhr.open("POST", `http://wineapi/api/users/${userData.id}`, true);
            xhr.open("POST", `${process.env.REACT_APP_API_URL}/users/${userData.id}`, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        console.log('Actualización exitosa:', this.responseText);
                        const user = JSON.parse(this.responseText);
                        localStorage.setItem('userLogged', JSON.stringify({ username: user.username, profile: user.profile, wines: user.wines, average: user.average, id: user.id }));
                        navigate(0);  // Redirigir a la página de perfil o a otra según convenga
                    } else {
                        console.error('Error al actualizar:', this.status, this.responseText);
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleConfirmPassword = async (e) => {
        e.preventDefault();

        // Clear previous errors
        setErrors({});
        const passwordRegex = /^.{8,}$/;
        if (!passwordRegex.test(formData.newPassword)) {
            setErrors(errors => ({ ...errors, password: "La contraseña debe tener al menos 8 caracteres" }));
            return;
        }

        // Comprobar que las contraseñas coinciden
        if (formData.newPassword !== formData.confirmNewPassword) {
            setErrors(errors => ({ ...errors, password: "No coinciden las contraseñas" }));
            return;
        }

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('current_password', formData.password);
            data.append('new_password', formData.newPassword);

            xhr.open("POST", `${process.env.REACT_APP_API_URL}/pass/${userData.id}`, true);
            //xhr.open("POST", `https://admin.winegang.es/api/pass/${userData.id}`, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        console.log('Actualización exitosa:', this.responseText);
                        const user = JSON.parse(this.responseText);
                        navigate('/');  // Redirigir a la página de perfil o a otra según convenga
                    } else {
                        console.error('Error al actualizar:', this.status, this.responseText);
                        setErrors(errors => ({ ...errors, password: "Error al actualizar" }));
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };
    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <div className="winegangs-container">
            <div className="register-container">
                <h2>Editar usuario</h2>
                <form>
                    <div className="form-line">
                        <label htmlFor="username">Usuario</label>
                        <input type="text" id="username" value={formData.username} onChange={handleChange} placeholder="Juan" />

                    </div>
                    {errors.username && <p className="error">{errors.username}</p>}
                    <div className="form-line">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={formData.email} onChange={handleChange} placeholder="juan@example.com" />
                    </div>
                    {errors.email && <p className="error">{errors.email}</p>}
                    <button onClick={handleConfirm}>Cambiar datos</button>
                    <div className="form-line">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" id="password" value={formData.password} onChange={handleChange} placeholder="********" />
                    </div>
                    <div className="form-line">
                        <label htmlFor="confirmPassword">Nueva Contraseña</label>
                        <input type="password" id="newPassword" value={formData.newPassword} onChange={handleChange} placeholder="********" />
                    </div>
                    <div className="form-line">
                        <label htmlFor="confirmPassword">Repetir nueva Contraseña</label>
                        <input type="password" id="confirmNewPassword" value={formData.confirmNewPassword} onChange={handleChange} placeholder="********" />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                    <button onClick={handleConfirmPassword}>Cambiar contraseña</button>
                </form>
                <button className="close-button" onClick={handleGoBack}><img src={close}></img></button>
            </div>
        </div>
    );
}
