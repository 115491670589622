import React, { useEffect, useRef, useState } from 'react';
import { PolarArea } from 'react-chartjs-2';
import Slider from 'react-input-slider';
import icon from '../images/icon-g.png';
import instructions from '../images/instruct-g.png';
import Rinstructions from '../images/r-instruct-g.png';
import cartelD from '../images/cartel-d.png';
import cartelA from '../images/cartel-a.png';
import cartelT from '../images/cartel-t.png';
import cartelC from '../images/cartel-c.png';
import cartelF from '../images/cartel-f.png';


const handleQuitInstructions = () => {
    const instructionsDiv = document.getElementById('instructions-div');
    if (instructionsDiv) {
        instructionsDiv.style.display = 'none';
    }
};


const NumericInputSlide = ({ actual }) => {

    const getInitialSliderValues = () => {
        const savedValues = JSON.parse(localStorage.getItem('selected6'));
        return savedValues || { a: 0, b: 0, c: 0, d: 0, e: 0 };
    };

    const [sliderValues, setSliderValues] = useState(getInitialSliderValues);


    const [sliderValue, setSliderValue] = useState(sliderValues.a);
    const [sliderValue2, setSliderValue2] = useState(sliderValues.b);
    const [sliderValue3, setSliderValue3] = useState(sliderValues.c);
    const [sliderValue4, setSliderValue4] = useState(sliderValues.d);
    const [sliderValue5, setSliderValue5] = useState(sliderValues.e)

    const handleSliderChange = (value) => {
        setSliderValue(value);
        setSliderValues(prevValues => ({
            ...prevValues,
            a: value  // Asegurándose de utilizar value.x para obtener el valor del slider
        }));

    };
    const handleSliderChange2 = (value) => {
        setSliderValue2(value);
        setSliderValues(prevValues => ({
            ...prevValues,
            b: value  // Asegurándose de utilizar value.x para obtener el valor del slider
        }));
    };
    const handleSliderChange3 = (value) => {
        setSliderValue3(value);
        setSliderValues(prevValues => ({
            ...prevValues,
            c: value  // Asegurándose de utilizar value.x para obtener el valor del slider
        }));
    };
    const handleSliderChange4 = (value) => {
        setSliderValue4(value);
        setSliderValues(prevValues => ({
            ...prevValues,
            d: value  // Asegurándose de utilizar value.x para obtener el valor del slider
        }));
    };
    const handleSliderChange5 = (value) => {
        setSliderValue5(value);
        setSliderValues(prevValues => ({
            ...prevValues,
            e: value  // Asegurándose de utilizar value.x para obtener el valor del slider
        }));
    };

    const getWordFromValue = (value) => {
        const words = ['Seco', 'Casi seco / medio', 'Dulce'];
        return words[value];
    };
    const getWordFromValue2 = (value) => {
        const words = ['Baja', 'Media', 'Alta'];
        return words[value];
    };
    const getWordFromValue3 = (value) => {
        const words = ['Bajo', 'Medio', 'Alto'];
        return words[value];
    };
    const getWordFromValue4 = (value) => {
        const words = ['Poco', 'Medio', 'Mucho'];
        return words[value];
    };
    const getWordFromValue5 = (value) => {
        const words = ['Corto', 'Medio', 'Largo'];
        return words[value];
    };

    const data = {
        labels: ['', '', '', '', ''],
        datasets: [
            {
                label: 'Acidez',
                data: [sliderValue2 + 1, 0, 0, 0, 0],
                backgroundColor: 'rgba(210, 164, 62, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Tanino',
                data: [0, sliderValue3 + 1, 0, 0, 0],
                backgroundColor: 'rgba(106, 16, 16, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 1,
            },
            {
                label: 'Cuerpo',
                data: [0, 0, sliderValue4 + 1, 0, 0],
                backgroundColor: 'rgba(84, 34, 61, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Final',
                data: [0, 0, 0, sliderValue5 + 1, 0],
                backgroundColor: 'rgba(29, 154, 120, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Dulzor',
                data: [0, 0, 0, 0, sliderValue + 1],
                backgroundColor: 'rgba(167, 149, 129, 0.7)',
                borderColor: 'rgba(167, 149, 129, 0)',
                borderWidth: 3,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: 'rgba(255, 255, 255, 1)', // Color de las líneas del grid
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.5)', // Color del grid
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: {
                    display: false,
                },
                pointLabels: {
                    font: {
                        size: 0, // Establecer el tamaño de la fuente a 0
                    },
                    fontColor: 'white', // Establecer el color del texto como transparente
                },
            },
        },
        plugins: {
            legend: false, // Oculta la leyenda
            tooltip: { // Asegúrate de usar "tooltip" en singular para la versión 3.x en adelante
                enabled: true,
                callbacks: {
                    label: function (context) {
                        // Retorna solo el nombre del dataset. Ejemplo: 'Dataset 1'
                        // Para este ejemplo, se asume que el nombre del dataset está disponible.
                        // Si quieres cambiar el valor numérico por una palabra:
                        // return context.dataset.label + ': ' + 'TuPalabraAqui';
                        // Donde 'TuPalabraAqui' es la palabra que quieres mostrar en lugar del valor numérico.
                        return context.dataset.label;
                    },
                }
            },
        },
    };
    const [title, setTitle] = useState(null);
    useEffect(() => {
        const savedTest = JSON.parse(localStorage.getItem('currentTest') || '[]');
        setTitle(savedTest.title);
        localStorage.setItem('selected6', JSON.stringify(sliderValues));
    }, [sliderValues]);
    useEffect(() => {
        const nextButton = document.querySelector('.btn-next');
        
            nextButton.disabled = false; // Habilitar el botón
       
    });
    return (
        <div className="slide-container">
            <div className="overlay" id="instructions-div">
                <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
                <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
            </div>
            <img className="icon-test" src={icon} alt="icon" />
            <div className="actual-wine">{title}</div>
            <h2>Fase gustativa <span className="title-points">(5 pt)</span></h2>
            <div className="sliders-container">
                <div className="line line-a">
                    <img src={cartelD} alt="Imagen" className="line-image" />
                    <div className="input-slider-container slider0">
                        <Slider axis="x" x={sliderValue} xmax={2} onChange={({ x }) => handleSliderChange(x)} />
                        <div className="input-info">{getWordFromValue(sliderValue)}</div>
                    </div>
                </div>
                <div className="line line-b">
                    <img src={cartelA} alt="Imagen" className="line-image" />
                    <div className="input-slider-container slider2">
                        <Slider axis="x" x={sliderValue2} xmax={2} onChange={({ x }) => handleSliderChange2(x)} />
                        <div className="input-info">{getWordFromValue2(sliderValue2)}</div>
                    </div>
                </div>
                <div className="line line-c">
                    <img src={cartelT} alt="Imagen" className="line-image" />
                    <div className="input-slider-container slider3">
                        <Slider axis="x" x={sliderValue3} xmax={2} onChange={({ x }) => handleSliderChange3(x)} />
                        <div className="input-info">{getWordFromValue3(sliderValue3)}</div>
                    </div>
                </div>
                <div className="line line-d">
                    <img src={cartelC} alt="Imagen" className="line-image" />
                    <div className="input-slider-container slider4">
                        <Slider axis="x" x={sliderValue4} xmax={2} onChange={({ x }) => handleSliderChange4(x)} />
                        <div className="input-info">{getWordFromValue4(sliderValue4)}</div>
                    </div>
                </div>
                <div className="line line-e">
                    <img src={cartelF} alt="Imagen" className="line-image" />
                    <div className="input-slider-container slider5">
                        <Slider axis="x" x={sliderValue5} xmax={2} onChange={({ x }) => handleSliderChange5(x)} />
                        <div className="input-info">{getWordFromValue5(sliderValue5)}</div>
                    </div>
                </div>
                <div className="taste-chart-container"><PolarArea data={data} options={options} /></div>
            </div>
        </div>
    );
};

export default NumericInputSlide;
