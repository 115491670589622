import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Profile from '../components/profile';
import notify from '../images/notify.png';
export default function Home() {

    const [profileInfo, setProfileInfo] = useState({
        username: '',
        average: 0.00,
        wines: 0,
        profile: 0,
        id: 0
    });
    const [notifications, setNotifications] = useState({ invitations: [], winegangs: [] });
    const [profileReload, setProfileReload] = useState(false); // Nuevo estado en Home
    const navigate = useNavigate();

    useEffect(() => {
        // Cargar la información del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                username: userInfo.username,
                average: userInfo.average || 0.00,
                wines: userInfo.wines || 0,
                id: userInfo.id || 0,
                profile: userInfo.profile || 'default'
            });
            fetchUserData(userInfo);
        }
    }, []);

    const fetchUserData = (userInfo) => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/userlog/${userInfo.id}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    // Verificar si el email recibido coincide con el guardado
                    if (data.email === userInfo.email) {
                        const updatedUserInfo = {
                            ...userInfo,
                            average: data.average,
                            wines: data.wines,
                            username: data.username
                        };
                        localStorage.setItem('userLogged', JSON.stringify(updatedUserInfo));
                        setProfileInfo(updatedUserInfo);
                        setProfileReload(prev => !prev);
                        
                    } else {
                        // Si no coincide, elimina userLogged de localStorage y redirige al login
                        localStorage.removeItem('userLogged');
                        navigate('/login');
                    }
                } else {
                        // Si no coincide, elimina userLogged de localStorage y redirige al login
                        localStorage.removeItem('userLogged');
                        navigate('/login');
                    }
            }
        };
        xhr.send();
    };

    const fetchNotifData = () => {
        if (profileInfo && profileInfo.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/hasNotifications/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    setNotifications({
                        invitations: data.invitations || [],
                        winegangs: data.winegangs || []
                    });
                    console.log(data);
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) { // Añadido check para asegurar que profileInfo tiene un id
            fetchNotifData();
        }
    }, [profileInfo]);

    const shouldShowNotification = () => {
        return notifications.invitations.length > 0 || notifications.winegangs.length > 0;
    };



  return (
      <div className="home">
          <Profile profileReload={profileReload} />
        <div className="home-top">
         
              <button className="corner-button arch"><Link to={`/stats/${profileInfo.id}`}>Estadísticas</Link></button>
        </div>
      <div className="button-container">
              <button className="corner-button top-left"><img src={notify} alt="Icono" className="notify-image" style={{ display: shouldShowNotification() ? 'block' : 'none' }} /><Link to="/gangs">WineGang</Link></button>
              <button className="main-button"><Link to="/choose-wine">Juega</Link></button>
              <button className="corner-button top-right"><Link to="/ranking">Ranking</Link></button>
              
      </div>
    </div>
  
  )
}
