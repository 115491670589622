import React, { useState, useEffect } from 'react';
import { PolarArea } from 'react-chartjs-2';
import icon from '../images/icon-n.png';
import ricon from '../images/icon-n-r.png';
import instructions from '../images/instruct-n.png';
import Rinstructions from '../images/r-instruct-n.png';


const handleQuitInstructions = () => {
    const instructionsDiv = document.getElementById('instructions-div');
    if (instructionsDiv) {
        instructionsDiv.style.display = 'none';
    }
};
const Selector = ({ opciones, seleccion, onSeleccionChange }) => {
    return (
        <select value={seleccion} onChange={(e) => onSeleccionChange(e.target.value)}>
            <option value="" disabled>Seleccione</option>
            {opciones.map((opcion) => (
                <option key={opcion.id} value={opcion.id}>
                    {opcion.nombre}
                </option>
            ))}
        </select>
    );
};


const SlideComponent = ({ actual, first, second, onSaveAnswers }) => {
    const [test, setTest] = useState(null);
    const [currentTest, setCurrentTest] = useState(null)
    const datosDePrueba = first.question
    const [selector1, setSelector1] = useState('');
    const [selector2, setSelector2] = useState('');
    const [selector3, setSelector3] = useState('');
    const [sliderValue, setSliderValue] = useState(0);
    const savedAnswer = JSON.parse(localStorage.getItem('currentAnswer') || '[]');
    const selectedButtonGroup0 = savedAnswer.selectedButtonGroup0;
    const selectedButtonGroup1 = savedAnswer.selectedButtonGroup1;
    const selectedButtonGroup2 = savedAnswer.selectedButtonGroup2;
    const selectedButtonGroup3 = savedAnswer.selectedButtonGroup3;
    const selectedButtonGroup4 = savedAnswer.selectedButtonGroup4;
    const selectedButtonGroup5 = savedAnswer.selectedButtonGroup5;
    const selectedButtonGroup6 = savedAnswer.selectedButtonGroup6;
    const selectedButtonGroup7 = savedAnswer.selectedButtonGroup7;
    const selectedButtonGroup8 = savedAnswer.selectedButtonGroup8;
    const [title, setTitle] = useState(null);

    console.log(selectedButtonGroup6);

    
    useEffect(() => {
        console.log(title);
        const nextButton = document.querySelector('.btn-next');
        if (nextButton) {
            nextButton.disabled = !(selector1 && selector2 && selector3 && sliderValue !== 0);
        }
    }, [selector1, selector2, selector3, sliderValue]);
    useEffect(() => {
        const savedTest = JSON.parse(localStorage.getItem('currentTest') || '[]');
        setTitle(savedTest.title);
        const currentTest = JSON.parse(localStorage.getItem('currentTest'));
        if (currentTest) {
            fetchTestDetails(currentTest.id);
        }
    }, []);
    const fetchTestDetails = (testId) => {
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/getTest/${testId}`;
        xhr.open("GET", url, true);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                setTest(data.object);
                setCurrentTest(data.title);
                console.log("Test Details Loaded: ", data);
            }
        };
        xhr.send();
    };
   

    const opciones1 = datosDePrueba.map(d => ({ id: d.variedad, nombre: d.variedad }));

    const opciones2 = selector1
        ? datosDePrueba.find(d => d.variedad === selector1)?.paises.map((p, index) => ({ id: p.pais, nombre: p.pais }))
        : [];

    const opciones3 = selector2
        ? datosDePrueba.find(d => d.variedad === selector1)?.paises.find(p => p.pais === selector2)?.regiones.map((r, index) => ({ id: r.region, nombre: r.region }))
        : [];

    const data = {
        labels: ['', '', '', '', ''],
        datasets: [
            {
                label: 'Acidez',
                data: [selectedButtonGroup6?.b + 1 || 0, 0, 0, 0, 0],
                backgroundColor: 'rgba(210, 164, 62, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Tanino',
                data: [0, selectedButtonGroup6?.c + 1 || 0, 0, 0, 0],
                backgroundColor: 'rgba(106, 16, 16, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 1,
            },
            {
                label: 'Cuerpo',
                data: [0, 0, selectedButtonGroup6?.d + 1 || 0, 0, 0],
                backgroundColor: 'rgba(84, 34, 61, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Final',
                data: [0, 0, 0, selectedButtonGroup6?.e + 1 || 0, 0],
                backgroundColor: 'rgba(29, 154, 120, 0.7)',
                borderColor: 'rgba(29, 154, 120, 0)',
                borderWidth: 3,
            },
            {
                label: 'Dulzor',
                data: [0, 0, 0, 0, selectedButtonGroup6?.a + 1 || 0],
                backgroundColor: 'rgba(167, 149, 129, 0.7)',
                borderColor: 'rgba(167, 149, 129, 0)',
                borderWidth: 3,
            },
        ],
    };
    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)', // Color de las l�neas del grid
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)', // Color del grid
                },
                gridLines: {
                    color: 'rgba(0, 0, 0, 1)', // Color del grid exterior
                },
                suggestedMin: 0,
                suggestedMax: 3,
                ticks: {
                    display: false,
                },
                pointLabels: {
                    font: {
                        size: 0, // Establecer el tama�o de la fuente a 0
                    },
                    fontColor: 'white', // Establecer el color del texto como transparente
                },
            },
        },
        plugins: {
            legend: false, // Oculta la leyenda
            tooltip: { // Aseg�rate de usar "tooltip" en singular para la versi�n 3.x en adelante
                enabled: true,
                callbacks: {
                    label: function (context) {
                        // Retorna solo el nombre del dataset. Ejemplo: 'Dataset 1'
                        // Para este ejemplo, se asume que el nombre del dataset est� disponible.
                        // Si quieres cambiar el valor num�rico por una palabra:
                        // return context.dataset.label + ': ' + 'TuPalabraAqui';
                        // Donde 'TuPalabraAqui' es la palabra que quieres mostrar en lugar del valor num�rico.
                        return context.dataset.label;
                    },
                }
            },
        },
    };

    let tastea;
    if (selectedButtonGroup6.a === 0) {
        tastea = 'Seco';
    } else if (selectedButtonGroup6.a === 1) {
        tastea = 'Casi seco / medio';
    } else if (selectedButtonGroup6.a === 2) {
        tastea = 'Dulce';
    } else {
        tastea = ''; // default value
    }
    let tasteb;
    if (selectedButtonGroup6.b === 0) {
        tasteb = 'Baja';
    } else if (selectedButtonGroup6.b === 1) {
        tasteb = 'Media';
    } else if (selectedButtonGroup6.b === 2) {
        tasteb = 'Alta';
    } else {
        tasteb = ''; // default value
    }
    let tastec;
    if (selectedButtonGroup6.c === 0) {
        tastec = 'Bajo';
    } else if (selectedButtonGroup6.c === 1) {
        tastec = 'Medio';
    } else if (selectedButtonGroup6.c === 2) {
        tastec = 'Alto';
    } else {
        tastec = ''; // default value
    }
    let tasted;
    if (selectedButtonGroup6.d === 0) {
        tasted = 'Poco';
    } else if (selectedButtonGroup6.d === 1) {
        tasted = 'Medio';
    } else if (selectedButtonGroup6.d === 2) {
        tasted = 'Mucho';
    } else {
        tasted = ''; // default value
    }
    let tastee;
    if (selectedButtonGroup6.e === 0) {
        tastee = 'Corto';
    } else if (selectedButtonGroup6.e === 1) {
        tastee = 'Medio';
    } else if (selectedButtonGroup6.e === 2) {
        tastee = 'Largo';
    } else {
        tastee = ''; // default value
    }

    const RangoInput = () => {
        const [valor, setValor] = useState(sliderValue); // Inicializar el estado local con el valor global

        useEffect(() => {
            setValor(sliderValue); // Sincronizar el estado local con el estado global en cada renderizado
        }, [sliderValue]); // Aseg�rate de incluir sliderValue en las dependencias

        const handleCambio = (e) => {
            setValor(e.target.value); // Actualizar el estado local mientras el usuario interact�a con el slider
        };

        const handleFinCambio = (e) => {
            setSliderValue(e.target.value); // Actualizar el estado global con el valor final del slider
        };


        return (
            <div className="input-price-container">
                
                <input
                    type="range"
                    id="rango"
                    name="rango"
                    min="0"
                    max="100"
                    value={valor}
                    onChange={handleCambio}
                    onMouseUp={handleFinCambio}
                    onTouchEnd={handleFinCambio} 
                />
                <p>Precio: {valor}&euro;</p>
            </div>
        );
    };

    return (
        <div className="slide-container">
            <div className="overlay" id="instructions-div">
                <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
                <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
            </div>
            <img className="icon-test note-icon-test icon-dekstop" src={icon} alt="icon" />
            <img className="icon-test note-icon-test icon-mobile" src={ricon} alt="icon" />
            <div className="actual-wine">{title}</div>
            <h2>Nota de cata</h2>
            <div className="cont-sub-section">
            <div className="text-note col-sub-section-2">
                    <div className="section-title">Nota de cata</div>
                    <p>Este vino tiene un color <span>{selectedButtonGroup1} {selectedButtonGroup0}</span>. En nariz tiene una <span>intensidad {selectedButtonGroup2}</span> con aromas primarios <span>({selectedButtonGroup3.join(", ")})</span>, aromas secundarios <span>({selectedButtonGroup4.join(", ")})</span> y aromas terciarios <span>({selectedButtonGroup5.join(", ")}). </span>
                        Es un vino <span>{tastea}</span>,
                        con acidez <span>{tasteb}</span>,
                        tanino <span>{tastec}</span>,
                        cuerpo <span> {tasted} </span>
                        y final <span>{tastee}</span>.</p>
                    <div className="note-chart-container"><PolarArea data={data} options={options} /></div>
            </div>
            <div className="col-sub-section-2">
                    <div className="note-select-container">
                        <h4>Variedad</h4>
                        <select>
                            <option>{selectedButtonGroup7.selector1}</option>
                        </select>
                    </div>
                    <div className="note-select-container">
                        <h4>Pa&iacute;s</h4>
                        <select>
                            <option>{selectedButtonGroup7.selector2}</option>
                        </select>
                    </div>
                    <div className="note-select-container">
                        <h4>Regi&oacute;n</h4>
                        <select>
                            <option>{selectedButtonGroup7.selector3}</option>
                        </select>
                    </div>
                <div className="note-select-container">
                <h4>Precio</h4>
                        <div className="input-price-container">

                            <input
                                type="range"
                                id="rango"
                                name="rango"
                                min="0"
                                max="100"
                                value={selectedButtonGroup8}
                            />
                            <p>Precio: {selectedButtonGroup8}&euro;</p>
                        </div>
                </div>
            </div>
            </div>
            
        </div>

    );
};

export default SlideComponent;