// src/components/Profile.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import userImage from '../images/users/0.jpeg';

const Profile = ({ profileReload }) => { // Recibe profileReload como prop
    const [profileInfo, setProfileInfo] = useState({
        username: '',
        average: 0.00,
        wines: 0,
        profile: 0,
        id: 0
    });

    const loadProfileInfo = () => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            setProfileInfo({
                username: userInfo.username,
                average: userInfo.average || 0.00,
                wines: userInfo.wines || 0,
                id: userInfo.id || 0,
                profile: userInfo.profile || 'default'
            });
        }
    };

    // Recargar profileInfo cada vez que profileReload cambie
    useEffect(() => {
        loadProfileInfo();
    }, [profileReload]); // Se ejecuta cuando profileReload cambia

    const getProfileImageUrl = () => {
        if (profileInfo && profileInfo.profile) {
            try {
                return require(`../images/users/${profileInfo.profile}.jpeg`);
            } catch (err) {
                console.error('Failed to load user image:', err);
                return userImage; // Retorna imagen por defecto si falla
            }
        }
        return userImage;  // Imagen por defecto si profile es vac�o
    };

    if (!profileInfo) return null; // Retorna null si no hay datos en localStorage

    return (
        <div className="user-info">
            <Link to={`/user/${profileInfo.id}`}>
                <div className="user-details">
                    <img src={getProfileImageUrl()} alt="Imagen de usuario" className="user-image" />
                    <div className="user-text">
                        <div className="user-name">
                            {profileInfo.username} ({profileInfo.average + '%'})
                        </div>
                        <div className="user-group">
                            Vinos catados ({profileInfo.wines})
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Profile;
