import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Pagina1 from '../testComponents/visualComponent';
import Pagina2 from '../testComponents/olfactoryComponent';
import Pagina3 from '../testComponents/tasteComponent';
import Pagina4 from '../testComponents/noteComponent';
import Pagina5 from '../testComponents/orderComponent';
import TwoButtons from '../components/twoButtons';
import instructions from '../images/instruct-w.png';
import Rinstructions from '../images/r-instruct-w.png';


const handleQuitInstructions = () => {
    const instructionsDiv = document.getElementById('instructions-div');
    if (instructionsDiv) {
        instructionsDiv.style.display = 'none';
    }
};
const SliderComponent = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
   
    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (isIOS) {
            document.body.classList.add("safari");
        }

        // Función de limpieza que se ejecuta cuando el componente se desmonta
        return () => {
            if (isIOS) {
                document.body.classList.remove("safari");
            }
        };
    }, []);

    
    const showPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };
    const handleShowInstructions = () => {
        const instructionsDiv = document.getElementById('instructions-div');
        if (instructionsDiv) {
            instructionsDiv.style.display = 'block';
        }
    };

    const handleHelpClick = (url) => {
  window.open('https://google.es', '_blank');
};



    const initialWines = ['A', 'B', 'C', 'D'];

    const [availableWines, setAvailableWines] = useState(initialWines);
    const [selectedWines, setSelectedWines] = useState([]);

    const handleWineClick = (wine) => {
        const newAvailableWines = availableWines.filter((w) => w !== wine);
        const newSelectedWines = [...selectedWines, wine];

        setAvailableWines(newAvailableWines);
        setSelectedWines(newSelectedWines);
    };

    const handleClearSelected = () => {
        setAvailableWines([...availableWines, ...selectedWines]);
        setSelectedWines([]);
    };
    const handleSaveOrder = () => {
        const userLogged = JSON.parse(localStorage.getItem('userLogged'));

        if (!userLogged) {
            console.error("User information is missing.");
            return;
        }

        const selectedWines = document.querySelectorAll('.wine-container-second .selected-wine');
        if (selectedWines.length !== 4) {
            console.error("Not all wine positions are selected.");
            return;
        }

        const order = {
            user_id: userLogged.id,
            first: selectedWines[0]?.textContent.trim() || "",
            second: selectedWines[1]?.textContent.trim() || "",
            third: selectedWines[2]?.textContent.trim() || "",
            fourth: selectedWines[3]?.textContent.trim() || "",
        };

        console.log('Sending order:', order);

        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/storeWinesOrder`;
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 201) {
                    console.log('Order saved successfully');
                    localStorage.removeItem('selectedWines');
                    window.location.href = '/';
                } else {
                    console.error('Error saving order:', xhr.responseText);
                    window.location.href = '/';
                }
            }
        };
        xhr.send(JSON.stringify(order));
    };


    return (
        <div className="test-container">
            <div className="slider-container my-3">
                <div className="slide-container">
                    <div className="overlay" id="instructions-div">
                        <img src={instructions} alt="Imagen" className="centered-image dekstop" onClick={handleQuitInstructions} />
                        <img src={Rinstructions} alt="Imagen" className="centered-image mobile" onClick={handleQuitInstructions} />
                    </div>
                    <h2 className="mb-3">Vino del Mes</h2>
                    <div className="cont-sub-section">
                        <div className="col-sub-section-2 wine-container wine-container-first">
                            <div className="section-title">Vinos catados</div>
                            {availableWines.map((wine) => (
                                <button className={`wine-bottle wine-bottle-${wine}`} key={wine} onClick={() => handleWineClick(wine)}>
                                    <span>{wine}</span>
                                </button>
                            ))}
                        </div>
                        <div className="col-sub-section-2 wine-container wine-container-second">
                            <div className="section-title">Mejor vino</div>
                            <div className="wine-order">
                                <span>1&ordm;</span><span>2&ordm;</span><span>3&ordm;</span><span>4&ordm;</span></div>
                            {selectedWines.map((wine, index) => (
                                <div key={index} className={`wine-bottle selected-wine wine-bottle-${wine}`}>
                                    <span>{wine}</span>
                                </div>
                            ))}
                            {selectedWines.length > 0 && (
                                <div>
                                    <button className="delete-button" onClick={handleClearSelected}>X</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>


                <div className="btn-test-container">
                    <button className="btn btn-back"></button>
                    <button className={`btn btn-next ${selectedWines.length === 4 ? '' : 'disabled'}`} onClick={showPopup}>Enviar</button>
                </div>
                <div className="btn-second-test-container">
                    <button className="btn btn-inst" onClick={handleShowInstructions}><span>Instrucciones</span></button>
                </div>
            </div>
            <TwoButtons />
            {isPopupVisible && (
                <Popup
                    text="Estás a punto de enviar tu orden de preferencia para los vinos de este mes, una vez enviado no podrás cambiarlo ¿Estás seguro?"
                    onClose={closePopup}
                    onConfirm={handleSaveOrder} // Aquí pasamos la función handleSaveAnswers
                />
            )}
        </div>
    );
};

function Popup({ text, onClose, onConfirm }) {
    return (
        <div className="custom-overlay">
            <div className="custom-popup">
                <p>{text}</p>
                <button onClick={onClose}>Cerrar</button>
                <button onClick={onConfirm}>Enviar</button>
            </div>
        </div>
    );
}

export default SliderComponent;
